import React from "react";
import { Table, InputNumber, Button } from "antd";
import { currencyFormat } from "../utils/price";
import {
  getSelectedProductsColumns,
  getSelectedProductsDataSource,
} from "../utils/table";

/**
 * Компонент для отображения списка выбранных дверей (selectedProducts) 
 * и их аксессуаров (doorKnob, doorPanel, doorstep, latch).
 *
 * @param {Array} selectedProducts        Список товаров (каждый — дверь + потенциальные аксессуары)
 * @param {Function} setSelectedProducts  Функция обновления списка
 * @param {Function} deleteSelectedProduct  Коллбэк для удаления самой «двери»
 * @param {number} increasePrice         Глобальное удорожание (процент)
 * @param {boolean} isEditMode           true, если разрешено редактировать/удалять аксессуары
 */
const SelectedProductsList = ({
  selectedProducts,
  setSelectedProducts,
  deleteSelectedProduct,
  increasePrice = 0,
  isEditMode = false, 
}) => {
  // Основные колонки (для дверей)
  const columns = getSelectedProductsColumns();

  // Формируем «строки» таблицы (двери)
  const dataSource = getSelectedProductsDataSource(
    selectedProducts,
    setSelectedProducts,
    deleteSelectedProduct,
    increasePrice
  );

  // Колонки для под-таблицы «аксессуаров»
  const accessoryColumns = [
    {
      title: "Аксессуар",
      dataIndex: "label",
      key: "label",
    },
    {
      title: "Цена",
      dataIndex: "basePrice",
      key: "basePrice",
      render: (val) => currencyFormat(val),
    },
    {
      title: "Скидка, %",
      dataIndex: "discount",
      key: "discount",
      render: (discount, accessory) => (
        <InputNumber
          min={0}
          max={100}
          style={{ width: 60 }}
          disabled={!isEditMode}
          value={discount}
          onChange={(val) =>
            updateAccessoryValue(accessory.parentRecord, accessory.key, "discount", val)
          }
        />
      ),
    },
    {
      title: "Удорож., %",
      dataIndex: "appreciation",
      key: "appreciation",
      render: (appreciation, accessory) => (
        <InputNumber
          min={0}
          max={100}
          style={{ width: 60 }}
          disabled={!isEditMode}
          value={appreciation}
          onChange={(val) =>
            updateAccessoryValue(accessory.parentRecord, accessory.key, "appreciation", val)
          }
        />
      ),
    },
    {
      title: "Сумма",
      dataIndex: "finalPrice",
      key: "finalPrice",
      render: (val) => currencyFormat(val),
    },
    {
      title: "Действия",
      key: "actions",
      render: (_, accessory) => (
        <Button
          danger
          disabled={!isEditMode}
          onClick={() => removeAccessory(accessory.parentRecord, accessory.key)}
        >
          Удалить
        </Button>
      ),
    },
  ];

  /**
   * Удаляем выбранный аксессуар: обнуляем соответствующее поле
   * (doorKnob, doorPanel, doorstep, latch), если совпадает accessoryKey
   */
  const removeAccessory = (doorRecord, accessoryKey) => {
    const newList = selectedProducts.map((p) => {
      if ((p.uuid || p._id) === doorRecord.key) {
        if (
          p.doorKnob &&
          (p.doorKnob._id === accessoryKey || p.doorKnob.name === accessoryKey)
        ) {
          p.doorKnob = null;
        }
        if (
          p.doorPanel &&
          (p.doorPanel._id === accessoryKey || p.doorPanel.name === accessoryKey)
        ) {
          p.doorPanel = null;
        }
        if (
          p.doorstep &&
          (p.doorstep._id === accessoryKey || p.doorstep.name === accessoryKey)
        ) {
          p.doorstep = null;
        }
        if (
          p.latch &&
          (p.latch._id === accessoryKey || p.latch.name === accessoryKey)
        ) {
          p.latch = null;
        }
      }
      return p;
    });
    setSelectedProducts(newList);
  };

  /**
   * Обновляем скидку (accessoryDiscount) или удорожание (accessoryAppreciation)
   * у нужного аксессуара
   */
  const updateAccessoryValue = (doorRecord, accessoryKey, field, newVal) => {
    const newList = selectedProducts.map((p) => {
      if ((p.uuid || p._id) === doorRecord.key) {
        const checkAndUpdate = (acc) => {
          if (!acc) return;
          const isSame = acc._id === accessoryKey || acc.name === accessoryKey;
          if (isSame) {
            if (field === "discount") acc.accessoryDiscount = newVal;
            if (field === "appreciation") acc.accessoryAppreciation = newVal;
          }
        };
        checkAndUpdate(p.doorKnob);
        checkAndUpdate(p.doorPanel);
        checkAndUpdate(p.doorstep);
        checkAndUpdate(p.latch);
      }
      return p;
    });
    setSelectedProducts(newList);
  };

  return (
    <Table
      bordered
      pagination={false}
      columns={columns}
      dataSource={dataSource}
      expandable={{
        expandedRowRender: (record) => {
          if (!record.accessories || record.accessories.length === 0) {
            return <div>Нет аксессуаров</div>;
          }
          // Преобразуем accessories для под-таблицы
          const accessoryData = record.accessories.map((acc) => ({
            key: acc.key || acc.label,
            label: acc.label,
            basePrice: acc.product?.price || 0,
            discount: acc.discount || 0,
            appreciation: acc.appreciation || 0,
            finalPrice: acc.finalPrice || 0,
            parentRecord: record,
          }));

          return (
            <Table
              size="small"
              columns={accessoryColumns}
              dataSource={accessoryData}
              pagination={false}
              style={{ marginLeft: 40 }}
            />
          );
        },
        rowExpandable: (record) =>
          record.accessories && record.accessories.length > 0,
      }}
    />
  );
};

export default SelectedProductsList;
