// kepler_front_new/src/pages/ManufactureStatus/utils/table.js

import { formatStringToDate } from "./format";
import { 
  AcceptApplicationButton, 
  RefuseApplicationButton, 
  CompleteApplicationButton 
} from "../components/ManufactureApplicationModal";
import { getDeadlineColor } from "../../../utils/deadlineColor";

export const getSpecificationProductsDataSource = (products = []) => {
  if (!Array.isArray(products)) {
    return [];
  }

  const dataSource = [];
  let i = 1;
  for (let product of products) {
    dataSource.push({
      number: i++,
      name: product.name,
      unit: "",
      amount: product.amount,
      size: product.size,
      opening: product.opening || "-",
      aluminium: product.aluminium,
      comment: product.comment || "",
    });
  }
  return dataSource;
};

export const specificationProductsColumn = [
  {
    title: "№",
    dataIndex: "number",
    key: "number",
  },
  {
    title: "Наименование товара",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Размеры",
    dataIndex: "size",
    key: "size",
  },
  {
    title: "Открывание",
    dataIndex: "opening",
    key: "opening",
  },
  {
    title: "Алюминий",
    dataIndex: "aluminium",
    key: "aluminium",
  },
  {
    title: "Кол - во",
    dataIndex: "amount",
    key: "amount",
    width: 75,
  },
];

export const orderStatusColumnsNew = [
  {
    key: "sentToProductionDate",
    title: "Дата",
    dataIndex: "sentToProductionDate",
    sorter: (a, b) =>
      formatStringToDate(a.date).getTime() -
      formatStringToDate(b.date).getTime(),
    width: 100,
  },
  {
    key: "agreementNumber",
    title: "№ дог.",
    dataIndex: "agreementNumber",
    sorter: (a, b) => a.agreementNumber - b.agreementNumber,
    width: 85,
  },
  {
    key: "fullName",
    title: (
      <div style={{ width: 300 }}>
        ФИО
      </div>
    ),
    dataIndex: "fullName",
    width: 195,
  },
  {
    key: "deadlineForAcception",
    title: "Срок",
    dataIndex: "deadlineForAcception",
    sorter: (a, b) =>
      formatStringToDate(
        new Date(a.data.deadlineForAcception).toLocaleDateString()
      ).getTime() -
      formatStringToDate(
        new Date(b.data.deadlineForAcception).toLocaleDateString()
      ).getTime(),
    width: 100,
  },
  {
    key: "amount",
    title: (
      <div style={{ width: 56 }}>
        Кол-во
      </div>
    ),
    dataIndex: "amount",
    sorter: (a, b) => a.amount - b.amount,
    width: 65,
  },
  {
    key: "aluminiumColor",
    dataIndex: "aluminiumColor",
    title: "Цвет",
    width: 100,
  },
  {
    key: "responsibleUser",
    title: "Ответственный",
    dataIndex: "responsibleUser",
    width: 125,
  },
  {
    key: "actions",
    title: "",
    dataIndex: "actions",
    width: 100,
  },
];

export const orderStatusColumnsActive = [
  {
    key: "sentToProductionDate",
    title: "Дата",
    dataIndex: "sentToProductionDate",
    sorter: (a, b) =>
      formatStringToDate(a.date).getTime() -
      formatStringToDate(b.date).getTime(),
    width: 100,
  },
  {
    key: "agreementNumber",
    title: "№ дог.",
    dataIndex: "agreementNumber",
    sorter: (a, b) => a.agreementNumber - b.agreementNumber,
    width: 85,
  },
  {
    key: "fullName",
    title: (
      <div style={{ width: 300 }}>
        ФИО
      </div>
    ),
    dataIndex: "fullName",
    width: 195,
  },
  {
    key: "deadline",
    title: "Срок",
    dataIndex: "deadline",
    sorter: (a, b) =>
      formatStringToDate(
        new Date(a.data.deadline).toLocaleDateString()
      ).getTime() -
      formatStringToDate(
        new Date(b.data.deadline).toLocaleDateString()
      ).getTime(),
    width: 100,
  },
  {
    key: "amount",
    title: (
      <div style={{ width: 56 }}>
        Кол-во
      </div>
    ),
    dataIndex: "amount",
    sorter: (a, b) => a.amount - b.amount,
    width: 65,
  },
  {
    key: "aluminiumColor",
    dataIndex: "aluminiumColor",
    title: "Цвет",
    width: 100,
  },
  {
    key: "responsibleUser",
    title: "Ответственный",
    dataIndex: "responsibleUser",
    width: 125,
  },
  {
    key: "actions",
    title: "",
    dataIndex: "actions",
    width: 100,
  },
];

export const orderStatusColumnsBooking = [
  {
    key: "date",
    title: "Дата",
    dataIndex: "date",
    sorter: (a, b) =>
      formatStringToDate(a.date).getTime() -
      formatStringToDate(b.date).getTime(),
    width: 95,
  },
  {
    key: "fullName",
    title: "ФИО",
    dataIndex: "fullName",
    width: 195,
  },
  {
    key: "agreementNumber",
    title: <div>№ дог.</div>,
    dataIndex: "agreementNumber",
    sorter: (a, b) => a.agreementNumber - b.agreementNumber,
    width: 80,
  },
  {
    key: "phoneNumber",
    title: "Телефон",
    dataIndex: "phoneNumber",
    width: 120,
  },
  {
    key: "address",
    title: "Адрес",
    dataIndex: "address",
    width: 200,
  },
  {
    key: "deadline",
    title: "Срок",
    dataIndex: "deadline",
    sorter: (a, b) =>
      formatStringToDate(
        new Date(a.data.deadline).toLocaleDateString()
      ).getTime() -
      formatStringToDate(
        new Date(b.data.deadline).toLocaleDateString()
      ).getTime(),
    width: 100,
  },
  {
    key: "aluminiumColor",
    dataIndex: "aluminiumColor",
    title: "Цвет",
    width: 100,
  },
  {
    key: "amount",
    title: <div>Кол-во</div>,
    dataIndex: "amount",
    sorter: (a, b) => a.amount - b.amount,
    width: 85,
  },
  {
    key: "responsibleUser",
    title: "Ответственный",
    dataIndex: "responsibleUser",
    width: 125,
  },
];

export const orderStatusColumnsCompleted = [
  {
    key: "sentToProductionDate",
    title: "Дата",
    dataIndex: "sentToProductionDate",
    sorter: (a, b) =>
      formatStringToDate(a.date).getTime() -
      formatStringToDate(b.date).getTime(),
    width: 100,
  },
  {
    key: "agreementNumber",
    title: "№ дог.",
    dataIndex: "agreementNumber",
    sorter: (a, b) => a.agreementNumber - b.agreementNumber,
    width: 80,
  },
  {
    key: "fullName",
    title: "ФИО",
    dataIndex: "fullName",
    width: 250,
  },
  {
    key: "phoneNumber",
    title: "Телефон",
    dataIndex: "phoneNumber",
    width: 120,
  },
  {
    key: "address",
    title: "Адрес",
    dataIndex: "address",
  },
  {
    key: "amount",
    title: "Кол-во",
    dataIndex: "amount",
    sorter: (a, b) => a.amount - b.amount,
    width: 87,
  },
  {
    key: "responsibleUser",
    title: "Ответственный",
    dataIndex: "responsibleUser",
    width: 130,
  },
  {
    key: "aluminiumColor",
    dataIndex: "aluminiumColor",
    title: "Цвет",
    width: 100,
  },
];

// Функция формирования dataSource для таблицы статусов
export const getOrderStatusDataSource = (leads = [], fetch) => {
  const getActions = (lead) => {
    if (lead.state === "NEW") {
      return (
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <AcceptApplicationButton lead={lead} fetch={fetch} />
          <RefuseApplicationButton lead={lead} fetch={fetch} />
        </div>
      );
    }
    if (lead.state === "ACTIVE") {
      return (
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <CompleteApplicationButton lead={lead} fetch={fetch} />
          <RefuseApplicationButton lead={lead} fetch={fetch} />
        </div>
      );
    }
    return null;
  };

  const dataSource = [];
  for (let lead of leads) {
    if (!lead.client) continue; // Если клиент не определён, пропускаем

    // В данном случае client уже является объектом, полученным из агрегации
    const client = lead.client;
    // Для ответственного пользователя берём данные из поля user, полученного через $lookup
    const responsibleUser = lead?.user
      ? `${lead.user.name || ""} ${lead.user.surname || ""}`.trim()
      : "";
      
    const props = lead.state === "NEW"
      ? getDeadlineColor(lead?.acceptionDeadlineForProduction, 1)
      : lead.state === "ACTIVE"
        ? getDeadlineColor(lead?.productionDeadline, 3)
        : {};

    const aluminiumColor = Array.isArray(lead?.aluminium)
      ? lead.aluminium.filter((v) => v !== "").join(", ")
      : "";

    dataSource.push({
      key: lead._id,
      data: lead,
      montage: lead.isHasMontage ? "Есть" : "Нет",
      agreementNumber: lead?.lead?.agreementNumber || "",
      phoneNumber: client?.phone || "",
      address: lead?.lead?.address || "",
      fullName: client?.name || "",
      responsibleUser, // теперь заполняется из lead.user
      deadline: (
        <div {...props}>
          {lead.productionDeadline
            ? new Date(lead.productionDeadline).toLocaleDateString()
            : ""}
        </div>
      ),
      deadlineForAcception: (
        <div {...props}>
          {lead.acceptionDeadlineForProduction
            ? new Date(lead.acceptionDeadlineForProduction).toLocaleDateString()
            : ""}
        </div>
      ),
      amount: lead?.doorCount ? parseInt(lead?.doorCount, 10) : 0,
      aluminiumColor,
      date: lead.createdAt ? new Date(lead.createdAt).toLocaleDateString() : "",
      sentToProductionDate: lead.sentToProductionDate
        ? new Date(lead.sentToProductionDate).toLocaleDateString()
        : "",
      actions: getActions(lead),
    });
  }
  console.log("dataSource", dataSource);
  return dataSource;
};
