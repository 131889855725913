import React, { useEffect, useState } from "react";
import { Table, Card, Layout, Input, Button, message, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { getRequest, patchRequest } from "../../utils/requests";
import config from "../../config.json";

/** Компонент для детальной информации (спецификация, документы) */
const DistributionFullInfo = ({ record, fetchData }) => {
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Загрузка спецификации (пример, если нужно)
  useEffect(() => {
    fetchSpecificationOrders(record._id);
    // eslint-disable-next-line
  }, [record]);

  const fetchSpecificationOrders = async (applicationId) => {
    try {
      // Пример запроса: /api/order?applicationId=...
      // const resp = await getRequest(`${config.service}/api/order?applicationId=${applicationId}`);
      // setOrders(resp.data || []);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Открыть документ
  const openDocument = (type, id) => {
    window.open(`${config.service}/api/file/${type}/${id}`);
  };

  // Пример колонок спецификации (если используете)
  const columnsSpec = [
    { title: "№", dataIndex: "index", key: "index", width: 50 },
    { title: "Наименование товара", dataIndex: "name", key: "name" },
    { title: "Размеры", dataIndex: "size", key: "size", width: 120 },
    { title: "Открывание", dataIndex: "opening", key: "opening", width: 130 },
    { title: "Алюминий", dataIndex: "aluminium", key: "aluminium", width: 80 },
    { title: "Кол-во", dataIndex: "quantity", key: "quantity", width: 60 },
    { title: "Стоимость", dataIndex: "price", key: "price", width: 80 },
  ];

  return (
    <Card>
      {/* Можно вывести заголовок, показывая, что за заявка */}
      <div style={{ marginBottom: 16 }}>
        <strong>Данные о заказе #{record._id}</strong>
        <div>Клиент: {record.clientName}</div>
        <div>Телефон: {record.phone}</div>
        <div>Адрес: {record.address}</div>
        <div>Статус: {record.status}</div>
      </div>

      <Table
        columns={columnsSpec}
        dataSource={orders.map((item, idx) => ({ ...item, key: idx, index: idx + 1 }))}
        loading={isLoading}
        pagination={false}
        size="small"
      />

      {/* Кнопки документов */}
      <div style={{ marginTop: 16 }}>
        <Button onClick={() => openDocument("agreement", record.lead)}>Договор</Button>
        <Button onClick={() => openDocument("specification", record._id)} style={{ marginLeft: 8 }}>
          Спецификация
        </Button>
        <Button onClick={() => openDocument("act", record._id)} style={{ marginLeft: 8 }}>
          Заявка на производство
        </Button>
        <Button onClick={() => openDocument("delivery-certificate", record._id)} style={{ marginLeft: 8 }}>
          Акт приема-передачи
        </Button>
        <Button onClick={() => openDocument("acceptance-act", record._id)} style={{ marginLeft: 8 }}>
          Акт выполненных работ
        </Button>
      </div>
    </Card>
  );
};

/** Основная страница распределения (только «Производство»). */
const DistributionPage = ({ userPermission }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Список доступных цехов для select
  const [companies, setCompanies] = useState([]);

  // При первом рендере (и при обновлении, если хотите) – загружаем данные
  useEffect(() => {
    fetchData();
    fetchCompanies();
  }, []);

  // Загрузка заявок (только "Производство")
  const fetchData = async () => {
    setIsLoading(true);
    try {
      // Хотите search → ?search=...
      const url = `${config.service}/api/distribution?type=PRODUCTION&search=${encodeURIComponent(searchTerm)}`;
      const resp = await getRequest(url);
      if (resp.data) {
        setList(resp.data);
      }
    } catch (err) {
      console.error(err);
      message.error("Ошибка при загрузке заявок");
    } finally {
      setIsLoading(false);
    }
  };

  // Загрузка компаний (цехов)
  const fetchCompanies = async () => {
    try {
      const resp = await getRequest(`${config.service}/api/company`);
      if (resp.data) {
        setCompanies(resp.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  // Поиск
  const handleSearch = () => {
    fetchData();
  };

  // Назначить заявку (applicationId) на компанию (companyId)
  const assignOrderToCompany = async (applicationId, companyId) => {
    if (!companyId) return;
    try {
      await patchRequest(`${config.service}/api/distribution/assign`, {
        applicationId,
        companyId,
      });
      message.success("Назначение успешно");
      fetchData();
    } catch (err) {
      console.error(err);
      message.error("Ошибка при назначении");
    }
  };

  // Форматирование даты
  const formatDate = (dateString) => {
    if (!dateString) return "";
    return new Date(dateString).toLocaleDateString();
  };
  const statusMapping = {
    REVIEW: "Ждёт отправки на производство",
  };
  const allowedFactoryIds = [
    '6793771cef9ccebe6e64833a',  // ID для "Промзводство 1"
    '67937726ef9ccebe6e64834d',  // ID для "Промзводство 2"
    '6793772cef9ccebe6e648360'    // ID для "Промзводство 3"
  ];
  // Описание колонок
  const columns = [
    {
      title: "Дата",
      dataIndex: "date",
      key: "date",
      width: 110,
      render: (val) => formatDate(val),
    },
    {
      title: "№ дог.",
      dataIndex: "agreementNumber",
      key: "agreementNumber",
      width: 80,
    },
    {
      title: "ФИО",
      dataIndex: "clientName",
      key: "clientName",
      width: 160,
    },
    {
      title: "Телефон",
      dataIndex: "phone",
      key: "phone",
      width: 120,
    },
    {
      title: "Адрес",
      dataIndex: "address",
      key: "address",
      width: 220,
    },
    {
      title: "Срок",
      dataIndex: "deadline",
      key: "deadline",
      width: 100,
      render: (val) => {
        const d = new Date(val);
        const isOverdue = d < new Date();
        return (
          <div style={{ backgroundColor: isOverdue ? "red" : "transparent", color: isOverdue ? "#fff" : "inherit" }}>
            {formatDate(val)}
          </div>
        );
      },
    },
    {
      title: "Ответственный",
      dataIndex: "responsibleUser",
      key: "responsibleUser",
      width: 120,
    },
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
      width: 160,
      render: (value) => statusMapping[value] || value,
    },
    {
      title: "Цех",
      key: "assignedCompany",
      width: 140,
      render: (_, record) => (
        <Select
          style={{ width: 130 }}
          allowClear
          placeholder="Выберите цех"
          value={record.assignedCompanyId || undefined}
          onChange={(val) => assignOrderToCompany(record._id, val)}
        >
          {companies
            .filter(c => allowedFactoryIds.includes(c._id)) // Фильтр по ID
            .map((c) => (
              <Select.Option key={c._id} value={c._id}>
                {c.name}
              </Select.Option>
            ))}
        </Select>
      ),
    },
  ];

  // Кастомная иконка раскрытия (опционально), чтобы «+» был компактнее
  const customExpandIcon = (props) => {
    const { expanded, onExpand, record } = props;
    return (
      <Button
        size="small"
        type="text"
        onClick={(e) => onExpand(record, e)}
        style={{ marginRight: 4 }}
      >
        {expanded ? "–" : "+"}
      </Button>
    );
  };

  return (
    <Layout style={{ padding: 15, minHeight: "100vh", background: "#fff" }}>
      <Card title="Распределение заказов (Производство)">
        {/* Поле для поиска */}
        <div style={{ marginBottom: 16, display: "flex", gap: 8 }}>
          <Input
            placeholder="Поиск по ключевому слову"
            style={{ width: 300 }}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Button type="primary" icon={<SearchOutlined />} onClick={handleSearch}>
            Поиск
          </Button>
        </div>

        <Table
          loading={isLoading}
          dataSource={list.map((item) => ({ ...item, key: item._id }))}
          columns={columns}
          pagination={false}
          // Настраиваем раскрытие строки
          expandable={{
            expandedRowRender: (record) => (
              <DistributionFullInfo record={record} fetchData={fetchData} />
            ),
            expandIcon: customExpandIcon,  // чтобы кнопка "плюс" была компактной
            expandRowByClick: false,
          }}
          size="small"
          scroll={{ x: 1300 }}
        />
      </Card>
    </Layout>
  );
};

export default DistributionPage;
