// kepler_front_new\src\pages\NewLead\utils\price.js

/**
 * Возвращает простую сумму всех товаров (без учёта скидок, аксессуаров и т.п.).
 */
export const getTotalPrice = (selectedProducts) => {
  if (!Array.isArray(selectedProducts) || selectedProducts.length === 0) {
    return 0;
  }

  let totalPrice = 0;
  for (let product of selectedProducts) {
    const p = product.product;
    const price = product.price || (p && p.price) || 0;
    const amount = product.amount || 1;
    totalPrice += price * amount;
  }

  return totalPrice;
};

/**
 * Высчитывает итоговую стоимость заказа, учитывая:
 *  - Базовую цену «двери»
 *  - Количество (amount)
 *  - Локальную скидку или удорожание на сам товар (orderDiscount, appreciationPercentage) — ТОЛЬКО на «дверь»
 *  - Четыре вида аксессуаров: doorKnob, doorPanel, doorstep, latch
 *  - Скидки/удорожания для каждого аксессуара (accessoryDiscount, accessoryAppreciation) — ТОЛЬКО на аксессуар
 *  - «Глобальное» удорожание контракта (priceIncrease), которое применяется к сумме «дверь + аксессуары».
 *
 * @param {Array} selectedProducts  Список выбранных товаров (каждый элемент может содержать:
 *    { price, amount, orderDiscount, appreciationPercentage,
 *      doorKnob, doorPanel, doorstep, latch }
 * @param {number} priceIncrease    «Глобальное» удорожание контракта в процентах (0..100)
 * @param {number} discount         (необязательно) «Глобальная» скидка, если вам нужна, 
 *                                  можно обнулить или вообще не использовать.
 * @returns {number} Итоговая сумма (тенге)
 */
export const getTotalPriceWithDiscount = (
  selectedProducts,
  priceIncrease = 0,
  discount = 0
) => {
  if (!Array.isArray(selectedProducts) || selectedProducts.length === 0) {
    return 0;
  }

  let total = 0;

  for (let product of selectedProducts) {
    // 1) Цена двери
    const basePrice = (product.price || (product.product && product.product.price)) || 0;
    const amount = product.amount || 1;
    let doorPrice = basePrice * amount;

    // 2) Локальное удорожание на дверь
    const appreciation = product.appreciationPercentage || 0;
    if (appreciation > 0) {
      const plusVal = Math.round(doorPrice * (appreciation / 100));
      doorPrice += plusVal;
    }

    // 3) Скидка на дверь (если есть)
    const doorDiscount = product.orderDiscount || 0;
    if (doorDiscount > 0) {
      const minusVal = Math.round(doorPrice * (doorDiscount / 100));
      doorPrice -= minusVal;
    }

    // 4) Если есть дополнительная «глобальная» скидка (discount), 
    //    и вы хотите её применять тоже ТОЛЬКО к двери, применяйте тут.
    //    ИЛИ уберите совсем.
    if (discount > 0) {
      const minusVal = Math.round(doorPrice * (discount / 100));
      doorPrice -= minusVal;
    }

    // 5) Считаем аксессуары отдельно
    let accessoriesSum = 0;
    const amountForAcc = amount; // если аксессуары тоже берутся в том же количестве
    function calcAccessoryPrice(acc) {
      let accPrice = (acc && acc.price) || 0;
      accPrice *= amountForAcc;

      // Удорожание аксессуара
      const accApp = acc.accessoryAppreciation || 0;
      if (accApp > 0) {
        const plusVal = Math.round(accPrice * (accApp / 100));
        accPrice += plusVal;
      }

      // Скидка на аксессуар
      const accDisc = acc.accessoryDiscount || 0;
      if (accDisc > 0) {
        const minusVal = Math.round(accPrice * (accDisc / 100));
        accPrice -= minusVal;
      }

      return accPrice;
    }

    if (product.doorKnob) {
      accessoriesSum += calcAccessoryPrice(product.doorKnob);
    }
    if (product.doorPanel) {
      accessoriesSum += calcAccessoryPrice(product.doorPanel);
    }
    if (product.doorstep) {
      accessoriesSum += calcAccessoryPrice(product.doorstep);
    }
    if (product.latch) {
      accessoriesSum += calcAccessoryPrice(product.latch);
    }

    // 6) Сумма: дверь (уже со скидкой) + аксессуары (со скидками)
    let rowSubtotal = doorPrice + accessoriesSum;

    // 7) "Глобальное" удорожание контракта (priceIncrease)
    if (priceIncrease > 0) {
      const plusVal = Math.round(rowSubtotal * (priceIncrease / 100));
      rowSubtotal += plusVal;
    }

    total += rowSubtotal;
  }

  return total;
};

/**
 * Простейший форматер чисел для отображения в таблице (с пробелами).
 */
export const currencyFormat = (num, minimumFractionDigits = 0) => {
  if (num == null || Number.isNaN(parseInt(num))) {
    return "0";
  }
  try {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "decimal",
      minimumFractionDigits,
    });
    return formatter.format(num).replace(/,/g, " ");
  } catch (e) {
    return "0";
  }
};

/**
 * Пример отдельного хелпера для подсчёта цены
 * c учётом discount/appreciation.
 */
export const getOrderPriceWithDiscountAndAppreciationPercentage = (
  productPrice,
  discount = 0,
  appreciationPercentage = 0
) => {
  if (!productPrice || isNaN(productPrice)) {
    return 0;
  }

  const appreciationValue = Math.round(productPrice * (appreciationPercentage / 100));
  const priceWithAppreciationPercentage = productPrice + appreciationValue;
  const discountValue = Math.round(priceWithAppreciationPercentage * (discount / 100));
  const resultWithDiscount = priceWithAppreciationPercentage - discountValue;
  return resultWithDiscount;
};
