import {
  DrawerForm,
  ProForm,
  ProFormText,
  ProFormSelect,
  ProFormCheckbox,
} from "@ant-design/pro-components";
import { Button, Form, Upload } from "antd";
import { MaskedInput } from "antd-mask-input";
import { useEffect, useState } from "react";
import Loading from "../../../components/Loading/Loading";
import {
  createUser,
  fetchCompanyPermissions,
  fetchCompanyUsers,
  fetchRoles,
} from "../utils/fetch";
import config from "../../../config.json";
import FormItem from "antd/es/form/FormItem";

const CreateUser = ({ setUsers, companyId }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [roles, setRoles] = useState([]);
  const [rolesOptions, setRolesOptions] = useState([]);
  const [visible, setVisible] = useState(false);
  const [companyPermissions, setCompanyPermissions] = useState({});
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    fetchRoles(setRoles);
    fetchCompanyPermissions(companyId, setCompanyPermissions, setIsLoading);
  }, [companyId]);

  useEffect(() => {
    if (!Array.isArray(roles)) {
      return;
    }

    const newList = [];
    for (let role of roles) {
      newList.push({
        label: role.name,
        value: role._id,
      });
    }

    setRolesOptions(newList);
  }, [roles]);

  const onCreatedUser = () => {
    setVisible(false);

    fetchCompanyUsers(companyId, setUsers);
  };

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const onPreview = async (file) => {
    let src = file.url;

    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);

        reader.onload = () => resolve(reader.result);
      });
    }

    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const handleSave = (values) => {
    const formdata = getBody(values);
    createUser(formdata, onCreatedUser);
  };

  const getBody = (values) => {
    const formdata = new FormData();

    formdata.append("name", values.name);
    formdata.append("surname", values.surname);
    formdata.append("phone", values.phone);
    formdata.append("password", values.password);
    formdata.append("roleId", values.roleId);
    formdata.append("companyId", companyId);

    // Добавление всех разрешений
    Object.keys(values).forEach(key => {
      if (typeof values[key] === 'boolean') {
        formdata.append(key, values[key]);
      }
    });

    if (fileList.length > 0) {
      const image = fileList[0].originFileObj;
      formdata.append("image", image, image.name);
    }

    return formdata;
  };

  return (
    <DrawerForm
      autoFocusFirstInput={true}
      trigger={
        <Button type="primary" onClick={() => setVisible(true)}>
          Пользователь
        </Button>
      }
      title="Создать пользователя"
      onFinish={(values) => {
        handleSave(values);
      }}
      visible={visible}
      onVisibleChange={setVisible}
    >
      {isLoading && <Loading />}
      {!isLoading && (
        <>
          <ProForm.Group>
            <div className="d-flex justify-content-start">
              <div>
                <FormItem label="Фото">
                  <Upload
                    listType="picture-card"
                    fileList={fileList}
                    onChange={onChange}
                    onPreview={onPreview}
                    accept={".png, .jpeg, .jpg"}
                    max={1}
                  >
                    {fileList.length < 1 && "+ Загрузить"}
                  </Upload>
                </FormItem>
              </div>
            </div>
          </ProForm.Group>
          <ProForm.Group>
            <ProFormText name="name" label="Имя" rules={[{ required: true }]} />
            <ProFormText
              name="surname"
              label="Фамилия"
              rules={[{ required: true }]}
            />
          </ProForm.Group>
          <ProForm.Group>
            <Form.Item
              label="Номер телефона"
              name="phone"
              rules={[{ required: true }]}
            >
              <MaskedInput mask={config.mask.phone} />
            </Form.Item>
            <ProFormText
              name="password"
              label="Пароль"
              rules={[{ required: true }]}
            />
            <ProFormSelect
              label="Роль"
              name="roleId"
              rules={[{ required: true }]}
              options={rolesOptions}
            />
          </ProForm.Group>
          <ProForm.Group>
            <ProFormCheckbox
              disabled={!companyPermissions?.createLead}
              name="createLead"
            >
              Создание заявки
            </ProFormCheckbox>
            <ProFormCheckbox
              disabled={!companyPermissions?.clients}
              name="clients"
            >
              Доступ к клиентам
            </ProFormCheckbox>
            <ProFormCheckbox
              disabled={!companyPermissions?.specification}
              name="specification"
            >
              Спецификация
            </ProFormCheckbox>
            <ProFormCheckbox disabled={!companyPermissions?.act} name="act">
              Заявка на производство
            </ProFormCheckbox>
            <ProFormCheckbox
              disabled={!companyPermissions?.agreement}
              name="agreement"
            >
              Договор
            </ProFormCheckbox>
            <ProFormCheckbox disabled={!companyPermissions?.KP} name="KP">
              КП
            </ProFormCheckbox>
          </ProForm.Group>
          <ProForm.Group>
            <ProFormCheckbox
              disabled={!companyPermissions?.payment}
              name="payment"
            >
              Счет на оплату
            </ProFormCheckbox>
            <ProFormCheckbox
              disabled={!companyPermissions?.deliveryCertificate}
              name="deliveryCertificate"
            >
              Акт приема-передачи
            </ProFormCheckbox>
            <ProFormCheckbox
              disabled={!companyPermissions?.acceptanceAct}
              name="acceptanceAct"
            >
              Акт выполненных работ
            </ProFormCheckbox>
          </ProForm.Group>
          <ProForm.Group>
            <ProFormCheckbox
              disabled={!companyPermissions?.products}
              name="products"
            >
              Товары
            </ProFormCheckbox>
            <ProFormCheckbox
              disabled={!companyPermissions?.orderStatus}
              name="orderStatus"
            >
              Статусы заказов
            </ProFormCheckbox>
            <ProFormCheckbox
              disabled={!companyPermissions?.production}
              name="production"
            >
              Запуск на производство
            </ProFormCheckbox>
          </ProForm.Group>
          <ProForm.Group>
            <ProFormCheckbox
              disabled={!companyPermissions?.changeState}
              name="changeState"
            >
              Принятие / отклонение заявок
            </ProFormCheckbox>
            <ProFormCheckbox
              disabled={!companyPermissions?.changeStatus}
              name="changeStatus"
            >
              Изменение статуса
            </ProFormCheckbox>
          </ProForm.Group>
          <ProForm.Group>
            <ProFormCheckbox
              disabled={!companyPermissions?.assemblyStatus}
              name="assemblyStatus"
            >
              Статусы сборки
            </ProFormCheckbox>
            <ProFormCheckbox
              disabled={!companyPermissions?.manufactureStatus}
              name="manufactureStatus"
            >
              Статусы производства
            </ProFormCheckbox>
            <ProFormCheckbox
              disabled={!companyPermissions?.deliveryStatus}
              name="deliveryStatus"
            >
              Статусы доставки
            </ProFormCheckbox>
            <ProFormCheckbox
              disabled={!companyPermissions?.installationStatus}
              name="installationStatus"
            >
              Статусы монтажа
            </ProFormCheckbox>
            <ProFormCheckbox
              disabled={!companyPermissions?.distribution}
              name="distribution"
            >
              Распределение
            </ProFormCheckbox>
          </ProForm.Group>
        </>
      )}
    </DrawerForm>
  );
};

export default CreateUser;
