export const getAluminium = (aluminium) => {
  // Если aluminium не является массивом – возвращаем пустую строку
  if (!Array.isArray(aluminium)) return "";
  // Фильтруем массив, удаляем пустые строки
  const filtered = aluminium.filter((item) => item !== "");
  // Если остался один элемент, возвращаем его, иначе соединяем элементы через запятую
  return filtered.length === 1 ? filtered[0] : filtered.join(", ");
};

export const getModalInnerText = (lead) => {
  // Если productionDeadline отсутствует – возвращаем пустую строку
  const deadline = lead.productionDeadline
    ? new Date(lead.productionDeadline).toLocaleDateString()
    : "Нет данных";
  
  // Проверяем, что lead.client существует и это непустой массив
  const client =
    Array.isArray(lead.client) && lead.client.length > 0
      ? lead.client[0]
      : { name: "Нет данных" };

  return (
    <div>
      <p style={{ marginBottom: 0 }}>
        Номер договора: <b>{lead.agreementNumber || "-"}</b>
      </p>
      <p style={{ marginBottom: 0 }}>
        Имя: <b>{client.name || "Нет имени"}</b>
      </p>
      <p style={{ marginBottom: 0 }}>
        Кол-во: <b>{lead.doorCount !== undefined ? lead.doorCount : "-"}</b>
      </p>
      <p style={{ marginBottom: 0 }}>
        Цвет: <b>{getAluminium(lead.aluminium)}</b>
      </p>
      <p style={{ marginBottom: 0 }}>
        Срок изготовления: <b>{deadline}</b>
      </p>
    </div>
  );
};
