// kepler_front_new/src/pages/ManufactureStatus/components/NewStatus.jsx
import { Table } from "antd";
import { useEffect, useState } from "react";
import { fetchStatuses } from "../utils/fetch";
import { getOrderStatusDataSource, orderStatusColumnsNew } from "../utils/table";
import OrderStatusFullInfo from "./OrderStatusFullInfo";

const NewStatus = ({ userPermission, query = "" }) => {
  const [leads, setLeads] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, [query]);

  const fetchData = () => {
    setIsLoading(true);
    // Используем fetchStatuses, который теперь обращается к /production-status
    fetchStatuses("NEW", "REVIEW", query, setLeads, setIsLoading);
  };

  return (
    <>
      <Table
        style={{ minWidth: 1000 }}
        size="small"
        bordered
        loading={isLoading}
        dataSource={getOrderStatusDataSource(leads, fetchData)}
        columns={orderStatusColumnsNew}
        pagination={false}
        scroll={{ y: 500 }}
        expandable={{
          expandedRowRender: (record) => (
            <OrderStatusFullInfo data={record.data} userPermission={userPermission} />
          ),
        }}
      />
    </>
  );
};

export default NewStatus;
