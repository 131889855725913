import React, { useState, useEffect } from 'react';
import NPSTable from './components/NPSTable';
import NPSCharts from './components/NPSCharts';
import { fetchClients } from './utils/fetch';
import { Button, ConfigProvider } from 'antd';
import CustomRangePicker from './components/CustomRangePicker';
import moment from 'moment';
import 'moment/locale/ru';
import ruRU from 'antd/es/locale/ru_RU';

moment.locale('ru');

const calculateAverageNPSByDay = (clients) => {
  const npsByDay = {};

  clients.forEach(client => {
    if (Array.isArray(client.npsScore)) {
      client.npsScore.forEach(nps => {
        const day = new Date(nps.date).toLocaleDateString('ru-RU', { weekday: 'short' });
        if (!npsByDay[day]) {
          npsByDay[day] = [];
        }
        npsByDay[day].push(nps.score);
      });
    }
  });

  const averageNPSByDay = Object.keys(npsByDay).map(day => {
    const total = npsByDay[day].reduce((acc, score) => acc + score, 0);
    const average = total / npsByDay[day].length;
    return { name: day, nps: parseFloat(average.toFixed(1)) };
  });

  const daysOrder = ['пн', 'вт', 'ср', 'чт', 'пт', 'сб'];
  averageNPSByDay.sort((a, b) => daysOrder.indexOf(a.name) - daysOrder.indexOf(b.name));

  return averageNPSByDay;
};

const calculateAverageNPSByPeriod = (clients, startDate, endDate) => {
  const npsByDate = {};
  const datesInRange = [];

  const start = new Date(startDate.setHours(0, 0, 0, 0));
  const end = new Date(endDate.setHours(0, 0, 0, 0));

  clients.forEach(client => {
    if (Array.isArray(client.npsScore)) {
      client.npsScore.forEach(nps => {
        const date = new Date(nps.date);
        if (date >= start && date <= end) {
          const formattedDate = date.toLocaleDateString('ru-RU');
          if (!npsByDate[formattedDate]) {
            npsByDate[formattedDate] = [];
          }
          npsByDate[formattedDate].push(nps.score);
        }
      });
    }
  });

  let currentDate = new Date(start);
  while (currentDate <= end) {
    const formattedDate = currentDate.toLocaleDateString('ru-RU');
    if (!npsByDate[formattedDate]) {
      npsByDate[formattedDate] = [];
    }
    datesInRange.push(formattedDate);
    currentDate.setDate(currentDate.getDate() + 1);
  }

  const averageNPSByDate = datesInRange.map(date => {
    const scores = npsByDate[date];
    const total = scores.reduce((acc, score) => acc + score, 0);
    const average = scores.length ? total / scores.length : 0;
    return { name: date, nps: parseFloat(average.toFixed(1)) };
  });

  return averageNPSByDate;
};

const calculateAverageNPS = (clients, targetDate) => {
  const scores = [];

  clients.forEach(client => {
    if (Array.isArray(client.npsScore)) {
      client.npsScore.forEach(nps => {
        const npsDate = new Date(nps.date);
        if (
          npsDate.getFullYear() === targetDate.getFullYear() &&
          npsDate.getMonth() === targetDate.getMonth() &&
          npsDate.getDate() === targetDate.getDate()
        ) {
          scores.push(nps.score);
        }
      });
    }
  });

  if (scores.length === 0) return 'N/A';

  const total = scores.reduce((acc, score) => acc + score, 0);
  return (total / scores.length).toFixed(1);
};

const NPSPage = () => {
  const [clients, setClients] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [limit, setLimit] = useState(10); 
  const [selectedChart, setSelectedChart] = useState('today');
  const [selectedDates, setSelectedDates] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    fetchClients({ page: 1, size: 99 }, (newClients) => {
      setClients(newClients);
      setIsLoading(false);
    });
  }, []);

  const handleDateChange = (dates) => {
    setSelectedDates(dates || []);
  };

  const loadMore = () => {
    setLimit(prevLimit => prevLimit + 10);
  };

  const npsToday = calculateAverageNPS(clients, new Date());
  const npsYesterday = calculateAverageNPS(clients, moment().subtract(1, 'day').toDate());

  const averageNPSByDay = calculateAverageNPSByDay(clients);
  const averageNPSByPeriod = selectedDates.length === 2 
    ? calculateAverageNPSByPeriod(clients, selectedDates[0].toDate(), selectedDates[1].toDate()) 
    : [];

  const sortedClients = [...clients].filter(client => client.npsScore.length > 0).sort((a, b) => {
    const dateA = new Date(a.npsScore?.[0]?.date || a.createdAt);
    const dateB = new Date(b.npsScore?.[0]?.date || b.createdAt);
    return dateB - dateA;
  });

  return (
    <ConfigProvider locale={ruRU}>
      <div>
        <h1>Оценка NPS</h1>
        <div style={{ marginBottom: '20px', display: 'flex', gap: '10px', justifyContent: 'flex-start' }}>
          <Button type={selectedChart === 'today' ? 'primary' : 'default'} onClick={() => setSelectedChart('today')}>
            NPS за сегодня
          </Button>
          <Button type={selectedChart === 'yesterday' ? 'primary' : 'default'} onClick={() => setSelectedChart('yesterday')}>
            NPS за вчера
          </Button>
          <Button type={selectedChart === 'week' ? 'primary' : 'default'} onClick={() => setSelectedChart('week')}>
            NPS за неделю
          </Button>
          <Button type={selectedChart === 'period' ? 'primary' : 'default'} onClick={() => setSelectedChart('period')}>
            Выбор периода
          </Button>
          {selectedChart === 'period' && (
            <CustomRangePicker onChange={handleDateChange} value={selectedDates} />
          )}
        </div>

        <div style={{ marginBottom: '20px' }}>
          <NPSCharts 
            selectedChart={selectedChart} 
            selectedDates={selectedDates}
            npsToday={npsToday}
            npsYesterday={npsYesterday}
            averageNPSByDay={averageNPSByDay}
            averageNPSByPeriod={averageNPSByPeriod}
          />
        </div>

        <h2>Последние NPS</h2>
        <div style={{ marginBottom: '20px' }}>
          <NPSTable 
            data={sortedClients.slice(0, limit)} 
            isLoading={isLoading} 
          />
        </div>
        {limit < sortedClients.length && (
          <div style={{ textAlign: 'center', margin: '20px 0' }}>
            <Button onClick={loadMore} loading={isLoading}>Показать еще</Button>
          </div>
        )}
      </div>
    </ConfigProvider>
  );
};

export default NPSPage;
