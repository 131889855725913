import React from 'react';
import {
  DrawerForm,
  ProForm,
  ProFormText,
  ProFormCheckbox,
} from '@ant-design/pro-components';
import { Button } from 'antd';
import { createCompany } from '../utils/fetch';

const CreateCompany = ({ setCompanies }) => {
  return (
    <DrawerForm
      onFinish={(values) => {
        const { name, ...permissions } = values;

        createCompany(
          {
            name,
            permission: permissions,
          },
          () => { },
          setCompanies
        );
      }}
      initialValues={{
        name: '',
        KP: false,
        act: false,
        agreement: false,
        changeState: false,
        changeStatus: false,
        clients: false,
        createLead: false,
        payment: false,
        production: false,
        products: false,
        specification: false,
        deliveryCertificate: false,
        acceptanceAct: false,
        assemblyStatus: false,
        manufactureStatus: false,
        deliveryStatus: false,
        installationStatus: false,
        distribution: false,
      }}
      title="Создать компанию"
      drawerProps={{
        destroyOnClose: true,
      }}
      trigger={
        <Button type="primary" style={{ marginRight: 15 }}>
          Компания
        </Button>
      }//Неправильные ключи,пофиксил
    ><ProForm.Group title="Основное">
        <ProFormText
          name="name"
          width="md"
          label="Название"
          tooltip="Название компании"
          placeholder="Название"
          rules={[{ required: true, message: 'Пожалуйста, введите название компании' }]}
        />
      </ProForm.Group>
      <ProForm.Group title="Документы">
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <StyledCheckbox name="agreement">Договор</StyledCheckbox>
          <StyledCheckbox name="specification">Спецификация</StyledCheckbox>
          <StyledCheckbox name="KP">КП</StyledCheckbox>
          <StyledCheckbox name="act">Заявка на производство</StyledCheckbox>
          <StyledCheckbox name="payment">Счет на оплату</StyledCheckbox>
          <StyledCheckbox name="deliveryCertificate">Акт приема-передачи</StyledCheckbox>
          <StyledCheckbox name="acceptanceAct">Акт выполненных работ</StyledCheckbox>
        </div>
      </ProForm.Group>
      <ProForm.Group title="Статусы">
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <StyledCheckbox name="orderStatus">Статусы заказов</StyledCheckbox>
          <StyledCheckbox name="production">Запуск на производство</StyledCheckbox>
          <StyledCheckbox name="changeState">Принятие / отклонение заявок</StyledCheckbox>
          <StyledCheckbox name="changeStatus">Изменение статуса</StyledCheckbox>
          <StyledCheckbox name="assemblyStatus">Статусы сборки</StyledCheckbox>
          <StyledCheckbox name="manufactureStatus">Статусы производства</StyledCheckbox>
          <StyledCheckbox name="deliveryStatus">Статусы доставки</StyledCheckbox>
          <StyledCheckbox name="installationStatus">Статусы монтажа</StyledCheckbox>
          <StyledCheckbox name="distribution">Распределение</StyledCheckbox>
        </div>
      </ProForm.Group>
      <ProForm.Group title="Функции">
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <StyledCheckbox name="createLead">Создание заявки</StyledCheckbox>
          <StyledCheckbox name="clients">Доступ к клиентам</StyledCheckbox>
          <StyledCheckbox name="products">Товары</StyledCheckbox>
        </div>
      </ProForm.Group>
    </DrawerForm>
  );
};

const StyledCheckbox = ({ children, ...rest }) => (
  <div style={{ maxWidth: '50%', marginBottom: 10 }}>
    <ProFormCheckbox {...rest}>{children}</ProFormCheckbox>
  </div>
);

export default CreateCompany;
