// kepler_front_new/src/pages/ManufactureStatus/utils/fetch.js
import { message } from "antd";
import config from "../../../config.json";
import { patchRequest, postRequest, getRequest } from "../../../utils/requests";

export const fetchStatuses = (
  state = "NEW",
  status = "",
  query = "",
  setSpecifications = () => {},
  setIsLoading = () => {}
) => {
  // Для страницы производства используем эндпоинт production-status
  const url = `${config.service}/api/appl/production-status?search=${encodeURIComponent(query)}`;

  const body = { state };
  if (status) {
    body.status = status;
  }

  postRequest(url, body, {})
    .then((data) => {
      // Ожидается, что сервер возвращает { orders: [...], totalPages: ..., currentPage: ... }
      setSpecifications(data.data.orders);
    })
    .catch((err) => {
      console.error("Ошибка загрузки статусов:", err);
      message.error("Ошибка загрузки данных");
    })
    .finally(() => {
      setIsLoading(false);
    });
};

export const fetchSpecificationOrders = (specificationId, setOrders = () => {}, setIsLoading = () => {}) => {
  const url = `${config.service}/api/appl/orders-for-specification/${specificationId}`;
  getRequest(url)
    .then((data) => {
      setOrders(data.data?.products.orders || []);
    })
    .catch((err) => {
      console.error("Ошибка загрузки спецификации:", err);
      message.error("Ошибка загрузки спецификации");
    })
    .finally(() => {
      setIsLoading(false);
    });
};

export const acceptApplication = (id, onFinish) => {
  const url = `${config.service}/api/appl/changeState/${id}`;
  const body = { state: "ACTIVE", status: "PRODUCTION" };

  const response = patchRequest(url, body);
  const hideLoadingMsg = message.loading("Загрузка...", 0);
  response
    .then(() => {
      message.success("Готово", 3);
    })
    .catch(() => {
      message.error("Ошибка", 3);
    })
    .finally(() => {
      hideLoadingMsg();
      if (onFinish) onFinish();
    });
};

export const refuseApplication = (id, onFinish) => {
  const url = `${config.service}/api/appl/changeState/${id}`;
  const body = { state: "NEW", status: "REFUSED" };

  const response = patchRequest(url, body);
  const hideLoadingMsg = message.loading("Загрузка...", 0);
  response
    .then(() => {
      message.success("Готово", 3);
    })
    .catch(() => {
      message.error("Ошибка", 3);
    })
    .finally(() => {
      hideLoadingMsg();
      if (onFinish) onFinish();
    });
};

export const completeApplication = (id, isHasAssembly, onFinish) => {
  const url = `${config.service}/api/appl/changeState/${id}`;
  const body = isHasAssembly
    ? { state: "ACTIVE", status: "PASSED_TO_ASSEMBLY" }
    : { state: "ACTIVE", status: "READY_FOR_SHIPMENT" };

  const response = patchRequest(url, body);
  const hideLoadingMsg = message.loading("Загрузка...", 0);
  response
    .then(() => {
      message.success("Готово", 3);
    })
    .catch(() => {
      message.error("Ошибка", 3);
    })
    .finally(() => {
      hideLoadingMsg();
      if (onFinish) onFinish();
    });
};

export const updateApplication = (id, body, onFinish) => {
  const url = `${config.service}/api/appl/changeStatus/${id}`;
  const response = patchRequest(url, body);
  const hideLoadingMsg = message.loading("Загрузка...", 0);
  response
    .then(() => {
      message.success("Обновлено", 3);
    })
    .catch(() => {
      message.error("Ошибка", 3);
    })
    .finally(() => {
      hideLoadingMsg();
      if (onFinish) onFinish();
    });
};

export const updateApplicationStatus = (id, body, onFinish) => {
  const url = `${config.service}/api/appl/changeStatus/${id}`;
  const response = patchRequest(url, body);
  const hideLoadingMsg = message.loading("Загрузка...", 0);
  response
    .then(() => {
      message.success("Обновлено", 3);
      if (onFinish) onFinish();
    })
    .catch(() => {
      message.error("Ошибка", 3);
    })
    .finally(() => {
      hideLoadingMsg();
    });
};

export const sendToProduction = (id) => {
  const hideLoadingMsg = message.loading("Отправка на производство", 0);
  const url = `${config.service}/api/appl/production/${id}`;
  const response = postRequest(url, { status: "REVIEW", state: "ACTIVE" });
  response
    .then(() => {
      message.success("Спецификация успешно отправлена на производство", 3);
    })
    .catch(() => {
      message.error("Не удалось отправить на производство", 3);
    })
    .finally(() => {
      hideLoadingMsg();
    });
};
